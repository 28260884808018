import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Axios from 'axios'
//import 'foundation-sites/dist/css/foundation.min.css'
//import 'foundation-sites/dist/css/foundation-prototype.min.css' 
import VueKonami from 'vue-konami'
import VueResizeObserver from "vue-resize-observer"
import VueCarousel from 'vue-carousel'
import '../public/static/app.css'

Vue.use(VueCarousel)

var VueScrollTo = require('vue-scrollto')
 
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: -50,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

Vue.prototype.$http = Axios

import VueResizeText from 'vue-resize-text'
 
Vue.use(VueResizeText)

import VueSweetalert2 from 'vue-sweetalert2'

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css'

Vue.use(VueSweetalert2)

import ToggleButton from 'vue-js-toggle-button'
 
Vue.use(ToggleButton)

Vue.use(VueKonami)
Vue.use(VueResizeObserver)

Vue.config.productionTip = false

Vue.prototype.$sleep = function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

new Vue({
  router,
  directives: { 'resize': VueResizeObserver },
  render: h => h(App)
}).$mount('#app')
