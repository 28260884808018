<template>
  <div id="app">
    <Home />
  </div>
</template>

<script>
// @ is an alias to /src
import Home from '@/views/Home.vue'

export default {
  name: 'App',
  components: {
    Home
  },
  data() {
    return {

    }
  },
  computed: {

  },
  methods: {

  }
}
</script>

<style lang="stylus">
//@import './static/app.css'
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700;900&display=swap');
body {
  overflow-x: hidden;
}

.mobmenu-enter-active, .mobmenu-leave-active {
  transition: all .5s ease;
}
.mobmenu-enter, .mobmenu-leave-to {
  opacity: 0;
  transform: translateX(1000px);
}

.modal-enter-active, .modal-leave-active {
  transition: all .5s ease;
}
.modal-enter, .modal-leave-to {
  opacity: 0;
  transform: translate(-1000px, 1000px) scale(0)
}
</style>
