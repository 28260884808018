<template>
  <div class="grid-container full fluid main-container no-padding" v-konami="activateEgg">
    <transition name="mobmenu">
      <div class="mobile-menu-container" v-if="showMobileMenu">
        <div class="menu-close"><span class="link" @click="showMobileMenu=false">X</span></div>
        <div class="menu-item" @click="mobileMenuClick('#scroll_services');trackEvent('menu', 'services')">
          Unsere Services –<br>
          exklusiv für Mitglieder
        </div>
        <div class="menu-item" @click="mobileMenuClick('#scroll_member');trackEvent('menu', 'membership')">
          Mitglied werden
        </div>
        <div class="menu-item" @click="mobileMenuClick('#scroll_contact');trackEvent('menu', 'contact')">
          Kontakt zum Bundesverband
        </div>
      </div>
    </transition>
    <transition name="modal">
      <div class="modal-container" v-if="modalVisible">
        <div class="modal">
          <div class="modal-head">
            <div class="close link" @click="modalVisible=false">x</div>
            <div class="title-1 m-title-2 text-center">
              UNABHÄNGIGKEITS&shy;SIEGEL
              <div class="green">ANFORDERN</div>
            </div>
          </div>
          <div class="grid-x input-container align-center">
            <div class="cell small-11 medium-8 large-7 xlarge-6 xxlarge-5">
              Name
              <div>
                <input type="text" v-model="siegelName" class="input-field" ref="siegelNameInput">
                <input class="input-email" placeholder="E-Mail-Adresse" v-model="siegelEmail">
              </div>
              <div class="toggles-container grid-x grid-margin-y">
                <div class="cell toggle small-12">
                  <div class="grid-x grid-margin-x align-top">
                    <div class="cell shrink">
                      <toggle-button v-model="siegelSwitch1"/>
                    </div>
                    <div class="cell auto text-left">
                      Ja, ich stimme den <a href="https://www.bundesverband-finanzdienstleistung.de/nutzungsbedingungen" target="_blank">Nutzungsbedingungen</a> zu.
                    </div>
                  </div>
                </div>
                <div class="cell toggle small-12">
                  <div class="grid-x grid-margin-x align-top">
                    <div class="cell shrink">
                      <toggle-button v-model="siegelSwitch2"/>
                    </div>
                    <div class="cell auto text-left">
                      Ja, ich erteile dem AfW die Erlaubnis zur Information und Kontaktaufnahme.
                    </div>
                  </div>
                </div>
                <div class="cell toggle small-12">
                  <div class="grid-x grid-margin-x align-top">
                    <div class="cell shrink">
                      <toggle-button v-model="siegelSwitch3"/>
                    </div>
                    <div class="cell auto text-left">
                      Ja, ich stimme den <a href="https://www.bundesverband-finanzdienstleistung.de/datenschutz" target="_blank">Datenschutzbestimmungen</a> zu.
                    </div>
                  </div>
                </div>
              </div>
              <div class="btn-container">
                <button @click="requestSiegel()" class="btn" :disabled="siegel.loading || !compSiegelFormValid">SIEGEL ANFORDERN</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div v-if="modalVisible" class="overlay">
    </div>
    <div class="grid-x grid-padding-x menu align-middle align-justify" :class="{'egg': egg}">
      <div class="cell small shrink">
        <img src="@/assets/logoneu.png" class="afwlogo">
      </div>
      <div class="cell small auto">
        <div class="grid-x align-right grid-padding-x grid-margin-x show-for-large">
          <div class="cell text-center shrink">
            <div class="link" v-scroll-to="'#scroll_services'" @click="trackEvent('menu', 'services')">Unsere Services – exklusiv für Mitglieder</div>
          </div>
          <div class="cell text-center shrink">
            <div class="link" v-scroll-to="'#scroll_member'" @click="trackEvent('menu', 'membership')">Mitglied werden</div>
          </div>
          <div class="cell text-center shrink">
            <div class="link" v-scroll-to="'#scroll_contact'" @click="trackEvent('menu', 'contact')">Kontakt zum Bundesverband</div>
          </div>
        </div>
        <div class="mob-menu hide-for-large">
          <img class="link" src="@/assets/menu.png" @click="showMobileMenu = true">
        </div>
      </div>
    </div>
    <div class="grid-x align-center justify-center text-center hero">
      <div class="cell small-12" id="hero-container" style="position:relative" :class="{'egg': egg}">
        <img id="background" class="hero-img" src="@/assets/hero2.jpg" v-if="!egg">
        <img id="background" class="hero-img" src="@/assets/hero2_egg.jpg" v-else>
        <div v-if="egg">
          <audio ref="music" autoplay>
            <source src="static/egg.mp3" type="audio/mpeg">
          </audio>
          <div v-for="n of 500" class="snow" :key="n">

          </div>
          
        </div>
      </div>
    </div>
    <div class="content-1">
      <div class="grid-x align-center text-center grid-padding-x">
        <div class="cell small-12 medium-10 large-9 xlarge-8 xxlarge-7 text-center">
          <div class="title-1 m-title-3">
            DER BUNDES&shy;VERBAND<br> 
            FINANZ&shy;DIENSTLEISTUNG AfW
          </div>
          <div class="title-2 m-title-1">
            IHRE INTERESSENS&shy;VERTRETUNG 
            IN BERLIN & BRÜSSEL
          </div>
        </div>
      </div>
      <div class="grid-x align-center text-left text-container-1 grid-padding-x">
        <div class="cell small-12 medium-10 large-8 xlarge-6 xxlarge-5">
          <div class="text-1 m-text-1">
            Die unabhängige Finanz- und Versicherungsberatung ist ständigem Druck ausgesetzt. Durch die zunehmende, behördliche Regulierung steigen Aufwand und Kosten existenzbedrohlich an. Durch angedachte politische Eingriffe in die Vergütungssysteme wird es zudem immer schwieriger, ein ausreichendes Einkommen zu erzielen. Alle unabhängigen Beraterinnen und Berater stehen deshalb vor der großen Herausforderung, ihr Geschäftsmodell und ihre berufliche Existenz zu schützen. Auch und ganz besonders, um den Menschen in Deutschland weiterhin eine anbieterneutrale Beratung und Produktauswahl zu ermöglichen.
            <br><br>
            <b>Als einziger Verband in Deutschland kämpft der Bundesverband Finanzdienstleistung – AfW ausschließlich für die Zukunft aller unabhängigen Finanzberaterinnen und Finanzberater, die gemäß §§ 34c, d, f, h, i Gewerbeordnung beraten – egal, wie groß ihr Unternehmen ist. Er vertritt ihre Interessen, indem er den politischen Prozess zur Willensbildung und das staatliche Handeln in Berlin und Brüssel kritisch und aktiv begleitet.</b>
          </div>
        </div>
      </div>
      <div class="grid-x align-center align-stretch grid-margin-x grid-margin-y text-left boxes-container">
        <div class="cell shrink">
          <div class="box">
            <div class="box-header">
              Entschlossen agieren<br>
              <span class="green">in Berlin & Brüssel</span>
            </div>
            <div class="box-content m-text-1">
              Der AfW pflegt konstruktive Arbeitskontakte zum Deutschen Bundestag und zum Europaparlament. In Ministerien und Ausschüssen setzt er sich engagiert für die Interessen all derjenigen ein, die unabhängig beraten.
            </div>
          </div>
        </div>
        <div class="cell shrink">
          <div class="box">
            <div class="box-header">
              Regierungspolitik<br>
              <span class="green">kritisch begleiten</span>
            </div>
            <div class="box-content m-text-1">
              Der AfW ist im direkten Austausch mit deutschen und europäischen Behörden, aber auch mit Dachverbänden und Brancheninitiativen. Dort platziert und vertritt er wichtige Themen und organisiert Diskussionen mit politisch Verantwortlichen und Fachleuten.
            </div>
          </div>
        </div>
        <div class="cell shrink">
          <div class="box">
            <div class="box-header">
              Überzeugende<br>
              <span class="green">Argumente liefern</span>
            </div>
            <div class="box-content m-text-1">
              Der AfW beauftragt wissenschaftliche Studien und Positions-Papiere rund um die Themen Versicherungs- und Finanzberatung. Seine Thesen untermauert er mit starken Argumenten. 
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-2" id="scroll_services">
      <div class="grid-x align-center text-center grid-padding-x">
        <div class="cell small-12 medium-10 large-9 xlarge-8 xxlarge-7">
          <div class="title-1 m-title-3">
            EXKLUSIV FÜR AfW-MITGLIEDER
          </div>
          <div class="title-2 m-title-1">
            UNSERE SERVICES
          </div>
        </div>
      </div>
      <div class="grid-x align-center text-left text-container-1 grid-padding-x">
        <div class="cell small-12 medium-10 large-8 x-large-7 xxlarge-6">
          <div class="text-1 m-text-1">
            <b>Freuen Sie sich auf jede Menge geldwerte Vorteile</b>
            <br><br>
            Als Mitglied des Bundesverbandes sichern wir nicht nur Ihre berufliche Zukunft, sondern bieten Ihnen Tag für Tag wertvolle Unterstützung. So haben Sie Anspruch auf Services, die Ihnen viel mehr bringen als die Mitgliedschaft kostet.
          </div>
        </div>
      </div>
      <div class="grid-x align-center align-stretch text-left services-container">
        <div class="cell small-12 medium-9 large-10 xlarge-9 xxlarge-8">
          <div class="grid-x grid-padding-x grid-margin-y grid-padding-y">
            <div class="cell small-12 medium-12 large-6 xlarge-6 xxlarge-6">
              <div class="service">
                <div class="service-top">
                  <img src="@/assets/rz_zeitung.jpg" class="header-img">
                </div>
                <div class="service-title">
                  <div class="top-title">
                    EXKLUSIV-SERVICE
                  </div>
                  <div class="bottom-title">
                    MITGLIEDER&shy;INFORMATION
                  </div>
                  <div class="sub-title">
                    Ihre News vom Vorstand
                  </div>
                </div>
                <div class="text m-text-1">
                  Wir halten Sie up-to-date. Was wird auf politischer Ebene geplant? Welche Änderungen stehen an? Wer früher über politische Entwicklungen Bescheid weiß, wird von ihnen später nicht eiskalt erwischt. Als Mitglied im Bundesverband erhalten Sie regelmäßig Infos für Ihr geschäftliches (Über-)Leben. Und können sich frühzeitig darauf einstellen.
                </div>
              </div>
            </div>
            <div class="cell small-12 medium-12 large-6 xlarge-6 xxlarge-6">
              <div class="service not-first">
                <div class="service-top">
                  <img src="@/assets/rz_gericht.jpg" class="header-img">
                </div>
                <div class="service-title">
                  <div class="top-title">
                    EXKLUSIV-SERVICE
                  </div>
                  <div class="bottom-title">
                    QUALIFIZIERTE AUSKUNFT
                  </div>
                  <div class="sub-title">
                    Ihre kostenlose Erstberatung
                  </div>
                </div>
                <div class="text m-text-1">
                  „Worauf muss ich achten; was darf ich machen und was nicht?” Als Mitglied im Bundesverband haben Sie Anspruch auf eine kompetente und kostenlose Erstberatung zu Recht, Steuern und Qualifikation.
                </div>
              </div>
            </div>
            <div class="cell small-12 medium-12 large-6 xlarge-6 xxlarge-6">
              <div class="service not-first">
                <div class="service-top" style="position:relative">
                  <img src="@/assets/rz_zeitschrift.jpg" @load="updateSize()" class="header-img" ref="referenceImage">
                  <div class="stoerer">
                    <div class="text text-center">
                      <div class="top">
                        WERT<br>PRO JAHR<br>BIS ZU
                      </div>
                      <div class="bottom">
                        500€
                      </div>
                    </div>
                  </div>
                </div>
                <div class="service-title">
                  <div class="top-title">
                    EXKLUSIV-SERVICE
                  </div>
                  <div class="bottom-title">
                    MEINUNGSFÜHRER
                  </div>
                  <div class="sub-title">
                    Viele renommierte Fachzeitschriften kostenlos frei Haus
                  </div>
                </div>
                <div class="text m-text-1">
                  Nach Ihrer Anmeldung haben Sie die Möglichkeit, eine Vielzahl hochkarätiger Fachmagazine - darunter Focus Money – kostenlos zu abonnieren. Sparen Sie so bis zu 500 Euro im Jahr. Und mit unseren Rabattangeboten für weitere Jahresabonnements sparen Sie sogar noch mehr. So bleiben Sie bestens informiert und Ihrer Zeit immer einen Schritt voraus.
                </div>
              </div>
            </div>
            <div class="cell small-12 medium-12 large-6 xlarge-6 xxlarge-6">
              <div class="service not-first">
                <div class="service-top siegel" :style="{'height': referenceImage.clientHeight + 'px'}">
                  <div class="grid-x grid-margin-x full-height align-center">
                    <div class="cell small-6 full-height left text-center siegel-container">
                      <div class="siegel-img-container">
                        <img src="@/assets/siegel2s.png" class="siegel-img">
                        <input class="siegel-name-input" v-model="siegelName" placeholder="Ihr Name">
                      </div>
                      <img src="@/assets/weisser_pfeil.png" class="white-arrow">
                    </div>
                    <div class="cell right small-6 text-center">
                      <div class="text text-left" v-resize-text="{ratio:1.4, minFontSize: '10px', maxFontSize: '18px'}">
                        Tragen Sie hier den Namen ein, auf den das Siegel ausgestellt werden soll!
                      </div>
                      <div class="btn" @click="modalVisible=true;trackEvent('CTA', 'Siegel erstellen')" v-resize-text="{ratio:1.4, minFontSize: '10px', maxFontSize: '18px'}">
                        Siegel erstellen
                      </div>
                    </div>
                  </div>
                </div>
                <div class="service-title">
                  <div class="top-title">
                    EXKLUSIV-SERVICE
                  </div>
                  <div class="bottom-title">
                    NEUTRALITÄTS-SIEGEL
                  </div>
                  <div class="sub-title">
                    Ihr persönliches Bekenntnis zur Unabhängigkeit
                  </div>
                </div>
                <div class="text m-text-1">
                  Als unabhängige Beraterin oder unabhängiger Berater sind Sie etwas ganz Besonderes – denn Sie sind nur den Interessen derer verpflichtet, von denen Sie Ihre Aufträge erhalten. Und das sollen alle sehen! Als Mitglied im Bundesverband Finanzdienstleistung – AfW haben Sie das Recht, mit der Auszeichnung für sich selbst und Ihre unabhängige und anbieterneutrale Beratung zu werben.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="grid-x align-center text-center bottom-container grid-padding-x">
        <div class="cell small-12 medium-10 large-9 xlarge-8 xxlarge-6">
          <div class="title-2 m-title-3">
            UND NOCH MEHR!
          </div>
          <div class="text-1 m-text-1">
            Als Mitglied werden Sie zu Verbandsveranstaltungen eingeladen, erhalten Zugang zu Mustervorlagen, profitieren von exklusiven Rabatten bei unseren Fördermitgliedern und können in unserem Vorteilsshop einkaufen.
          </div>
        </div>
      </div>
    </div>
    <div class="content-3" id="scroll_member">
      <div class="grid-x align-center text-left grid-padding-x">
        <div class="cell small-12 medium-10 large-10 xlarge-9 xxlarge-7">
          <div class="title-2 m-title-1">
            <div class="white">
              So werden Sie 
              offizielles Mitglied
            </div>
            des Bundes&shy;verband 
            Finanz&shy;dienstleistung 
            AfW
          </div>
          <div class="text-container m-text-1">
            Für nur 156 Euro im Jahr werden Sie offizielles Mitglied des Bundesverband Finanzdienstleistung, unterstützen aktiv die wichtige Arbeit Ihrer politischen Interessenvertretung und erhalten das Recht, die wertvollen Verbandsservices – ohne zusätzliche Kosten – zu nutzen. Beantragen Sie Ihre Mitgliedschaft hier:
          </div>
          <div class="text-center btn-container">
            <a @click="trackEvent('CTA', 'Antragsformular')" href="https://www.bundesverband-finanzdienstleistung.de/wp-content/uploads/2020/07/Mitgliederantrag_interaktiv.pdf" target="_blank">
              <div class="btn inline">
                Antragsformular
              </div>
            </a>
          </div>
          <div class="text-container-2">
            <div class="title-3 m-title-3">
              <div class="white">
                Zahlreiche Fördermitglieder unterstützen im<br class="show-for-large"> AfW aktiv die Interessen der unabhängigen
              </div>
                Finanzberatung in Deutschland.
            </div>
          </div>
          <div v-if="1==2" class="carousel-container text-center">
            <carousel
              :perPage="1"
              :navigationEnabled="true"
              :navigationNextLabel="`<img src='static/next.png'>`"
              :navigationPrevLabel="`<img src='static/prev.png'>`"
            >
              <slide>
                <img src="@/assets/pfefferminzia.png" >
              </slide>
              <slide>
                <img src="@/assets/pfefferminzia.png" >
              </slide>
            </carousel>
          </div>
        </div>
      </div>
    </div>
    <div class="content-4" id="scroll_contact">
      <div class="grid-x align-center text-left grid-padding-x">
        <div class="cell small-12 medium-10 large-9 xlarge-8 xxlarge-7">
          <div class="title-2 m-title-1">
            <div class="blue">
              Sie haben Fragen 
              zur Mitgliedschaft,
            </div>
            <div>
              den Leistungen und 
              Services Ihres 
              Berufs&shy;verbandes?
            </div>
          </div>
          <div class="grid-x align-left align-bottom grid-margin-x text-left image-container">
            <!--div class="cell small-12 medium-6 large-shrink">
              <img src="@/assets/buettner.jpg">
            </div-->
            <div class="cell medium-6 large-6 image-text">
              Sprechen Sie uns an. Sehr gern stehen wir Ihnen zur Verfügung und beantworten Ihre Fragen.
            </div>
          </div>
          <div class="btn-container grid-x">
            <div class="cell medium-6">
              <a href="tel:03063964370" @click="trackEvent('btn', 'anrufen')">
                <div class="btn btn1 text-center">
                  Anrufen
                </div>
              </a>
            </div>
            <div class="cell medium-6">
              <a href="mailto:office@afw-verband.de" @click="trackEvent('btn', 'email')">
                <div class="btn btn2 text-center">
                  E-Mail
                </div>
              </a>
            </div>
          </div>
          <div class="small-text-container m-text-1">
            <div class="text-left">
              <b>Unsere Geschäftsstelle:</b>
              <br><br>
              AfW – Bundesverband Finanzdienstleistung e. V.<br>
              Kurfürstendamm 37 | 10719 Berlin<br>
              <br>
              Tel.: 030 639643 7–0<br>
              E-Mail: <a href="mailto:office@afw-verband.de">office@afw-verband.de</a><br>
              <br><br>
              <span v-if="1==2">E-Mail: <a href="mailto:office@afw-verband.de">office@afw-verband.de</a><br></span>
              Mehr Informationen finden Sie auf der Website des <a href="https://bundesverband-finanzdienstleistung.de" target="_blank">Bundesverband Finanzdienstleistung – AfW</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-5 m-text-1">
      <div class="grid-x align-center text-center grid-padding-x">
        <div class="cell small-12">
          <div class="footer-title" v-if="1==2">
            DER BUNDESVERBAND<br>
            FINANZDIENSTLEISTUNG – AfW e.V.
          </div>
          <div class="footer-middle">
            <span class="link" v-scroll-to="'#scroll_services'" @click="trackEvent('menu', 'services')">Unsere Services – exklusiv für Mitglieder</span><br class="show-for-small-only"><span class="hide-for-small-only">&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span><span class="link" v-scroll-to="'#scroll_member'" @click="trackEvent('menu', 'membership')">Mitglied werden</span><br class="show-for-small-only"><span class="hide-for-small-only">&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span><span class="link" v-scroll-to="'#scroll_contact'" @click="trackEvent('menu', 'contact')">Kontakt zum Bundesverband</span>
          </div>
          <div class="footer-bottom">
            © 2022<br class="show-for-small-only"><span class="hide-for-small-only">&nbsp;&nbsp;&nbsp;</span><a href="https://www.bundesverband-finanzdienstleistung.de/impressum" target="_blank">Impressum</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a href="https://www.bundesverband-finanzdienstleistung.de/datenschutz" target="_blank">Datenschutz</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a href="https://www.bundesverband-finanzdienstleistung.de/nutzungsbedingungen" target="_blank">Nutzungsbedingungen</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a href="https://www.bundesverband-finanzdienstleistung.de" target="_blank">Der Bundesverband</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import VsModal from '@vuesimple/vs-modal'
const Isemail = require('isemail')

export default {
  name: 'Home',
  components: {
    VsModal
  },
  async mounted() {
    //await this.$sleep(1000)
    this.referenceImage.clientHeight = this.$refs.referenceImage.clientHeight
    window.addEventListener('resize', () => {
      this.updateSize()
    })
  },
  updated() {
    //this.referenceImage.clientHeight = this.$refs.referenceImage.clientHeight
  },
  data() {
    return {
      showMobileMenu: false,
      siegelEmail: '',
      siegelName: '',
      siegelSwitch1: false,
      siegelSwitch2: false,
      siegelSwitch3: false,
      siegel: {
        loading: false,
        showSuccess: false,
        showFailed: false
      },
      apiUrl: process.env.VUE_APP_API_URL,
      egg: false,
      modalVisible: false,
      referenceImage: {
        clientHeight: null
      }
    }
  },
  watch: {
    'referenceImage.clientHeight': function(n,o) {
      console.log(n,o)
    },
    'modalVisible': function(n,o) {
      if (n) {
        this.$nextTick(() => this.$refs.siegelNameInput.focus())
      }
    }
  },
  computed: {
    compSiegelHeight() {
      console.log(this.$refs)
      if (this.referenceImage) {
        console.log(this.referenceImage.clientHeight)
        console.log(this.referenceImage.clientHeight + 'px')
        return this.referenceImage.clientHeight + 'px'
      }
      console.log("auto")
      return "auto"
    },
    compSiegelFormValid() {
      if (this.siegelName.length < 2) return false
      if (!this.siegelSwitch1) return false
      if (!this.siegelSwitch3) return false
      if (!Isemail.validate(this.siegelEmail)) return false
      return true
    }
  },
  methods: {
    mobileMenuClick(id) {
      this.showMobileMenu = false
      this.$scrollTo(id)
    },
    async requestSiegel() {
      this.siegel.loading = true
      let data = {
        fullName: this.siegelName,
        mail: this.siegelEmail,
        contactAllowed: this.siegelSwitch2 ? 'Ja' : 'Nein'
      }
      try {
        await this.$http.post(this.apiUrl + '/sendcustomemail/badge', data)
        this.modalVisible = false
        this.$swal(
          {
            text: "Vielen Dank! Die Anfrage für Ihr persönliches Siegel wurde verschickt. Sie werden demnächst per E-Mail kontaktiert.",
            toast: true,
            timer: 15000,
            timerProgressBar: true,
            confirmButtonColor: "#86BD27",
            confirmButtonText: "Okay"
          }
        )
      } catch (error) {
        console.log(error)
      } finally {
        this.siegel.loading = false
      }
    },
    openModal() {
      this.$refs['modal1'].open();
    },
    updateSize() {
      this.referenceImage.clientHeight = this.$refs.referenceImage.clientHeight
    },
    async activateEgg() {
      this.egg = true
      console.log("refs:", this.$refs)
      await this.$sleep(500)
      this.$refs.music.volume = 0.2
      this.rain()
    },
    rain() {
      var parent = document.getElementById("hero-container")
      var options = {
        image: "background",
        parentElement: parent,
        gravityAngleVariance: 0,
        blur:0
      }
      var engine = new RainyDay(options)
      engine.pause()
      engine.rain([ [1, 6, 0.4], [1, 6, 0.8]])
      engine.resume()
    },
    trackEvent(category, action) {
      window._paq.push(['trackEvent', category, action]);
    }
  }
}
</script>

<style lang="scss">

.VueCarousel-navigation-button {
  top: 44%!important;
  outline: none!important;
}

@function random_range($min, $max) {
  $rand: random();
  $random_range: $min + floor($rand * (($max - $min) + 1));
  @return $random_range;
}

.snow {
  $total: 500;
  position: absolute;
  top:0px;
  width: 10px;
  height: 10px;
  background: white;
  border-radius: 50%;
  z-index:101;

  @for $i from 1 through $total {
    $random-x: random(1000000) * 0.0001vw;
    $random-offset: random_range(-100000, 100000) * 0.0001vw;
    $random-x-end: $random-x + $random-offset;
    $random-x-end-yoyo: $random-x + ($random-offset / 2);
    $random-yoyo-time: random_range(30000, 80000) / 100000;
    $random-yoyo-y: $random-yoyo-time * 100vh;
    $random-scale: random(10000) * 0.0001;
    $fall-duration: random_range(10, 30) * 0.3s;
    $fall-delay: random(30) * -1s;

    &:nth-child(#{$i}) {
      opacity: random(10000) * 0.0001;
      transform: translate($random-x, -10px) scale($random-scale);
      animation: fall-#{$i} $fall-duration $fall-delay linear infinite;
    }

    @keyframes fall-#{$i} {
      #{percentage($random-yoyo-time)} {
        transform: translate($random-x-end, $random-yoyo-y) scale($random-scale);
      }

      to {
        transform: translate($random-x-end-yoyo, 100vh) scale($random-scale);
      }
    }
  }
}
</style>

<style lang="stylus">
.mob-menu {
  text-align:right
}

button:disabled {
  transition: opacity 0.5s ease
  opacity:0.5
}
.link {
  cursor:pointer
}
.modal-container {
  z-index:111
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:100%
  height:100%
  position:fixed
  top:0px
  left:0px
  a {
    color: white
  }
}
.modal {
  background-color:#053160
  color:white
  z-index:101
  width:80%
  height:auto
  min-height:80%
  max-height:95%
  overflow-y: auto
  padding-bottom:50px
  .modal-head {
    padding-top:20px
    text-align:right
    .close {
      padding-right:30px
      text-align:right
      color: #86BD27
      font-size:30px
      line-height:1
      display: inline-block;
    }
  }
  .title-1 {
    color:white!important
  }
  .green {
    color: #86BD27
  }
  .input-container {
    margin-top: 60px
    color: #86BD27
    font-weight:900
    text-align:center
    .input-field {
      background-color:transparent
      font-size:18px
      text-align:left
      color:#86BD27
      margin-top:30px
      width:100%
      border: 0px solid transparent
      border-bottom: 1px solid #86BD27
      outline:none!important
      box-shadow:none
    }
    .input-email {
      margin-top:25px
      padding:15px
      font-size:18px
      color:white
      border-radius:8px
      border: 1px solid #F7F5F2
      background:transparent
      width:100%
    }
  }
  .toggles-container {
    line-height:1.1
    margin-top:60px
    color:white!important
    font-weight:normal!important
  }
  .btn-container {
    margin-top:60px
  }
}
.overlay {
  top:0px
  left:0px
  width:100%
  height:100%
  position:fixed
  z-index:110
  background-color:rgba(0,0,0,0.5)
}
.text-center {
  text-align:center
}
.text-left {
  text-align:left
}
canvas {
  top:0px!important
}
html, body {
  margin: 0;
  padding: 0;
}
body {
  background: #000;
  background: rgba(0, 0, 0, 1);
  overflow-x: hidden; 
}
.vs-modal__body {
  background-color: #053160
}

@media screen and (max-width: 64em) {
  .modal {
    width:95%!important
    .title-1 {
      margin-top:15px
    }
  }
}
</style>

<style lang="stylus" scoped>
@media screen and (max-width: 64em) {
  .modal {
    width:95%!important
  }
}
.hero {
  margin-top:80px
}
.main-container {
  overflow-x:hidden
  max-width:1920px
}
.m-title-1 {
  font-size: 66px
}
.m-title-2 {
  font-size: 50px
}
.m-title-3 {
  font-size: 35px
}
.m-text-1 {
  font-size:18px
}

.mobile-menu-container {
  text-transform: uppercase
  line-height:1.1
  font-weight:900
  z-index:200
  position:fixed
  top:0px
  left:0px
  width:100%
  height:100%
  color:white
  background:#053160
  padding-top:20px
  padding-left:20px
  padding-right:20px
  padding-bottom:20px
  font-size:23px
  text-decoration:none
  .menu-item {
    &:nth-child(2) {
      margin-top:60px
    }
    text-align:center
    margin-top: 45px
  }
  .menu-close {
    text-align:right
    font-weight:normal
  }
}

.main-container {
  max-width:1920px
  font-family: 'Nunito Sans'
  color:#2E2E38
}
.menu {
  background-color:white
  height:80px
  font-size:18px
  color: #787891
  position: fixed;
  width:100%
	top: 0;
  z-index:103
  &.egg {
    filter: grayscale(1) sepia(0.24);
  }
  .afwlogo {
    //height: 42px
  }
}
#hero-container {
  &.egg {
    filter: contrast(1) saturate(0.6) brightness(1.2) grayscale(0.4) sepia(0.10) blur(0.3px)
  }
  img {
    width:100%
  }
}
.hero-img {
  &.egg {
    filter: contrast(3) saturate(1.3)brightness(0.9) grayscale(1) sepia(0.24)
  }
}
.content-1 {
  background-color: #EFF2F5
  padding-top:90px
  padding-bottom:90px
}
.content-2 {
  background:white
  padding-top:80px
  padding-bottom:90px
  .title-1 {
    
  }
  .title-2 {
    margin-top:10px
  }
  .text-1 {
    //font-size:18px
    b {
      font-weight:900
    }
  }
  .bottom-container {
    margin-top:130px
    .title-2 {
      margin-top:0px
    }
    .text-1 {
      font-weight:bold
      margin-top:20px
    }
  }
}
.content-2 {
  .text-container-1 {
    text-align:center
  }
}
.content-3 {
  padding-top:120px
  padding-bottom: 160px
  background-color: #053160
  .title-2 {
    text-transform: uppercase
  }
  .text-container {
    margin-top:80px
    color:white
    //font-size:18px
  }
  .btn-container {
    margin-top:100px
    .btn {
      width:390px
      max-width:95%
    }
  }
  .text-container-2 {
    margin-top:180px
    text-align:center
  }
  .carousel-container {
    margin-top:110px
  }
}
.content-4 {
  background:white
  padding-top:125px
  padding-bottom:90px
  .title-1 {
    
  }
  .title-2 {
    margin-top:10px
  }
  .text-1 {
    //font-size:18px
  }
  .bottom-container {
    margin-top:130px
    .title-2 {
      margin-top:0px
    }
    .text-1 {
      font-weight:bold
      margin-top:20px
    }
  }
  .image-container {
    margin-top: 80px
  }
  .image-text {
    font-size:23px
    font-weight:bold
    line-height:1.3
  }
  .btn-container {
    margin-top:110px
    .btn {
      width:390px
      max-width:95%
    }
    .btn2 {
      //margin-top:40px
    }
  }
  .small-text-container {
    margin-top:120px
    //font-size:18px
  }
  a {
    color:inherit
    text-decoration:underline
  }
}
.content-5 {
  color: #053160
  //font-size: 18px
  background-color:#86BD27
  padding-top:10px
  padding-bottom:20px
  .footer-title {
    font-weight:bold
  }
  .footer-middle {
    margin-top:40px
  }
  .footer-bottom {
    margin-top:20px
  }
  a {
    color:inherit
    text-decoration:none
  }
}
.white {
  color:white
}
.title-1 {
  font-weight:900
  color: #053160
  line-height:1
}
.blue {
  color: #053160
}
.title-2 {
  margin-top:25px
  font-weight:900
  color: #86BD27
  line-height:1
  text-transform:uppercase
}
.title-3 {
  font-weight:900
  color: #86BD27
  line-height:1.1
}
.text-container-1 {
  margin-top:60px
}
.text-1 {
  //font-size:18px
}
.btn {
  cursor:pointer
  background-color: #86BD27
  color:white
  font-size:18px
  font-weight:900
  text-transform:uppercase
  border-radius:30px
  padding:20px 25px
  display:inline-block
  &.inline {
    display:inline-block
  }
}
.full-height {
  height:100%
}
.services-container {
  margin-top:90px
  .service {
    .service-top {
      position:relative
      .header-img {
        width:100%
      }
      .stoerer {
        position:absolute
        right:13%
        bottom:10%
        background-color: #2E2E38
        width:180px
        height:180px
        padding:10px
        border-radius:50%
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* add code below to also center horizontally */
        align-items: center;
        .text {
          margin-top:0px
          transform:rotate(-15deg)
          .top {
            color: #86BD27
            font-size:20px
            font-weight:900
            line-height:1.1
          }
          .bottom {
            margin-top:8px
            color:white
            font-size:40px
            font-weight:900
            line-height:1.1
          }
        }
      }
      img {
        width:auto
        max-width:100%
      }
    }
    .siegel {
      padding:10px
      background-color: #053160
      .left {

      }
      .right {
        .text {
          color:white
        }
        .btn {
          margin-top:25px
        }
      }
      .siegel-img-container {
        position:relative
        height:100%
        .siegel-img {
          height:100%
        }
        .siegel-name-input {
          position:absolute
          top:38%
          left:0px
          right:0px
          margin:0 auto
          width:90%
          text-align:center
          color:#053160
          font-size:16px
          height:29px
          border:none
          background:transparent
          outline:none
        }
      }
      .siegel-container {
        position:relative
        .white-arrow {
          position:absolute
          top: 34px;
          right: -31px;
          width: 75px;
        }
      }
    }
    .service-title {
      line-height:1.2
      margin-top:25px
      .top-title {
        color: #053160
        font-size:23px
        font-weight:900
      }
      .bottom-title {
        color: #86BD27
        font-size:35px
        margin-top:5px
        font-weight:900
      }
      .sub-title {
        color:#2E2E38
        font-size:18px
        margin-top:30px
        font-weight:700
      }
    }
    .text {
      //font-size:18px
      margin-top:30px
    }
  }
}

.boxes-container {
  margin-top:60px
}
.box {
  height:100%
  width:390px
  max-width:95vw
  border: 1px solid #053160
  border-radius:10px
  overflow:hidden
  .box-header {
    background: #053160
    padding:20px
    font-size:23px
    font-weight:900
    color:white
    line-height:1.2
    .green {
      color: #86BD27
    }
  }
  .box-content {
    padding: 25px 20px
    background:white
    height:100%
    //font-size:18px
  }
}


@media screen and (max-width: 89em) {
  .siegel-name-input {

  }
  .service {
    .service-top {
      .stoerer {
        width:150px!important
        height:150px!important
        .text {
          .top {
            font-size:18px!important
          }
          .bottom {
            font-size:30px!important
          }
        }
      }
    }
  }
}

@media screen and (max-width: 74em) {
  .m-title-1 {
    font-size:55px
  }
  .m-title-2 {
    font-size:40px
  }
  .menu {
    font-size:17px
  }
  .service {
    .service-top {
      .stoerer {
        width:130px!important
        height:130px!important
        .text {
          .top {
            font-size:16px!important
          }
          .bottom {
            font-size:24px!important
          }
        }
      }
    }
  }
}

@media screen and (max-width: 63em) {
  .m-title-1 {
    font-size:45px
  }
  .m-title-2 {
    font-size:32px
  }
  .menu {
    font-size:15px
  }
  .siegel {
    .siegel-img-container {
      .siegel-name-input {
        top:37%
      }
    }
  }
}

@media screen and (max-width: 39em) {
  .m-title-1 {
    font-size:30px
  }
  .m-title-2 {
    font-size:18px
  }
  .m-title-3 {
    font-size:18px
  }
  .m-text-1 {
    font-size:14px
  }
  .btn {
    font-size:14px
    padding:15px 20px
  }
  .content-1, .content-2, .content-3, .content-4, .content-5 {
    padding-left:10px
    padding-right:10px
  }
  .content-1 {
    padding-bottom:60px
    padding-top:30px
    .title-2 {
      margin-top: 15px
    }
    .text-container-1 {
      margin-top:30px
    }
    .boxes-container {
      margin-top:30px
    }
  }
  .content-2 {
    padding-top:50px
    padding-bottom:60px
    .title-1, .title-2 {
      text-align:left
    }
    .text-container-1 {
      margin-top:30px
      text-align:left
    }
    .bottom-container {
      margin-top:55px
      .title-2 {
        text-align:center
      }
    }
  }
  .services-container {
    margin-top:45px
    .service {
      &.not-first {
        margin-top:40px
      }
      .service-top {
        .stoerer {
          width:110px!important
          height:110px!important
          .text {
            .top {
              font-size:14px!important
            }
            .bottom {
              font-size:20px!important
            }
          }
        }
      }
      .service-title {
        .top-title {
          font-size:14px
        }
        .bottom-title {
          font-size:25px
        }
        .sub-title {
          font-size:14px
          margin-top:23px
        }
      }
    }
    .siegel {
      .siegel-container {
        .white-arrow {
          width: 60px!important
          right:-23px!important
        }
      }
      .siegel-img-container {
        .siegel-name-input {
          top:36%!important
          font-size:13px!important
        }
      }
    }
  }
  .content-3 {
    padding-top:55px
    padding-bottom:80px
    .title-2 {
      font-size:25px
    }
    .text-container {
      margin-top: 35px
    }
    .btn-container {
      margin-top:50px
      .btn {
        width:245px
      }
    }
    .text-container-2 {
      margin-top:70px
    }
    .carousel-container {
      margin-top:60px
    }
    ::v-deep .VueCarousel-navigation {
      display:none
    }
  }
  .content-4 {
    padding-top:55px
    padding-bottom:50px
    .title-2 {
      font-size:25px
    }
    .image-container {
      margin-top:30px
      img {
        max-width:60vw
      }
      .image-text {
        font-size:18px
        margin-top:20px
      }
    }
    .btn-container {
      margin-top:40px
      .btn2 {
        margin-top:20px
      }
      .btn {
        width:245px
      }
    }
    .small-text-container {
      margin-top: 40px
    }
  }
  .content-5 {
    padding-top:30px
    padding-bottom:30px
    .footer-middle {
      margin-top:20px
      font-size:12px
    }
    .footer-bottom {
      margin-top:20px
      font-size:12px
    }
  }
  .modal {
    .input-container {
      margin-top:40px
      .input-field {
        margin-top:25px
        font-size:16px
      }
      .input-email {
        font-size:16px
      }
    }
    .toggles-container {
      .toggle {
        font-size:16px!important
      }
    }
  }
}
</style>